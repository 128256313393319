import DOMPurify from 'dompurify'
import { Card } from '@mui/material'
import '../CultureSlider/CultureSlider.scss'

export const CultureCard = ({ link, title, image, shortText }) => {
    return (
        <>
            <a href={link} className="art-item">
                <Card id="card">
                    <div className="art-img-wrap">
                        <img alt={title} className="art-img" src={image} />
                        <div
                            className="name-cat"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(title),
                            }}
                        ></div>
                    </div>
                    <div>
                        <p
                            className="text bgClear"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(shortText),
                            }}
                        ></p>
                    </div>
                </Card>
            </a>
        </>
    )
}
