import './MyButton.scss'

export const TransparentButton = ({ onclick, text, bgc }) => {
    return (
        <>
            <button
                className="transparentBtn"
                onClick={onclick}
                style={{ background: bgc }}
            >
                {text}
            </button>
        </>
    )
}
