import { Container, Grid } from '@mui/material'
import React from 'react'
import DOMPurify from 'dompurify'
import { useParams } from 'react-router-dom'
import { getObject, projects } from 'utils/projects'
import './ProjectItem.scss'
import { Button } from 'Components/MyButton/Button'
import { CommentAider } from 'Components/CommentAider/CommentAider'
import { TitleBlock } from 'Components/TitleBlock/TitleBlock'

export const ProjectItem = ({
    projectObject = getObject(projects),
    goBack,
}) => {
    let { id } = useParams()

    return (
        <>
            <div className="humaPadding"></div>
            <Container>
                <TitleBlock title={projectObject[id].title} />
                <Button BtnText="<< Tous les projets" goBack={goBack} />
                <Grid container className="articleItem">
                    <Grid item sm={12} md={6}>
                        <figure>
                            <img
                                src="/Images/FcarteUkraine.jpg"
                                alt="Ukraine"
                            />{' '}
                            <figcaption>Ukraine</figcaption>
                        </figure>
                        <p>
                            Le 24 février 2022, la Russie a déclaré la guerre à
                            l’Ukraine. Cette invasion a jeté sur les routes plus
                            de 10 millions de personnes (en majorité des femmes,
                            des enfants et des personnes âgées). Trois millions
                            ont d’ores et déjà quitté le pays et se sont
                            réfugiés, pour la plupart en Europe de l’Ouest.
                            Cette présence fait peser sur les pays hôtes une
                            charge économique et sociale non négligeable. Mais
                            ce sont plus de sept millions de personnes qui ont
                            été déplacées et sont actuellement en Ukraine
                            complétement déracinées. Certaines personnes issues
                            de villes détruites à plus de 90 % (Kharkiv,
                            Marioupol, Tchernihiv, Borodianka, Severodonetsk…)
                            s’installent dans des zones où elles pensent être en
                            sécurité comme l’Ouest et le Sud-Ouest du pays.
                        </p>

                        <figure>
                            <img
                                src={projectObject[id].image[0]}
                                alt={projectObject[id].infoPlus[0]}
                            />
                            <figcaption>
                                {projectObject[id].infoPlus[0]}
                            </figcaption>
                        </figure>

                        <figure>
                            <img
                                src={projectObject[id].image[2]}
                                alt={projectObject[id].infoPlus[2]}
                            />
                            <figcaption>
                                {projectObject[id].infoPlus[2]}
                            </figcaption>
                        </figure>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <figure>
                            <img
                                src="/Images/FcarteZakarpattia.jpg"
                                alt="Ukraine"
                            />
                            <figcaption>Transcarpatie</figcaption>
                        </figure>

                        <figure>
                            <img
                                src={projectObject[id].image[1]}
                                alt={projectObject[id].infoPlus[1]}
                            />
                            <figcaption>
                                {projectObject[id].infoPlus[1]}
                            </figcaption>
                        </figure>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <p className="itemDiv">Objectif du projet:</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText1
                                ),
                            }}
                        ></p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText2
                                ),
                            }}
                        ></p>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <hr></hr>

                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText3
                                ),
                            }}
                        ></div>
                        <hr></hr>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CommentAider />
                        <figure>
                            <img
                                src={projectObject[id].image[3]}
                                alt={projectObject[id].infoPlus[3]}
                            />
                            <figcaption>
                                {projectObject[id].infoPlus[3]}
                            </figcaption>
                        </figure>
                        <figure>
                            <img
                                src={projectObject[id].image[4]}
                                alt={projectObject[id].infoPlus[4]}
                            />
                            <figcaption>
                                {projectObject[id].infoPlus[4]}
                            </figcaption>
                        </figure>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].table
                                ),
                            }}
                        ></div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText4
                                ),
                            }}
                        ></p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText5
                                ),
                            }}
                        ></p>
                        <div
                            className="pDarkTheme itemDiv"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].budget
                                ),
                            }}
                        ></div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <figure>
                            <img
                                src={projectObject[id].image[5]}
                                alt={projectObject[id].infoPlus[5]}
                            />
                            <figcaption>
                                {projectObject[id].infoPlus[5]}
                            </figcaption>
                        </figure>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText6
                                ),
                            }}
                        ></p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText7
                                ),
                            }}
                        ></p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    projectObject[id].shortText8
                                ),
                            }}
                        ></p>
                    </Grid>{' '}
                    <Button BtnText="Retour aux projets" goBack={goBack} />
                </Grid>
            </Container>
        </>
    )
}
