import { Grid } from '@mui/material'
import React from 'react'
import './CultureSlider.scss'
import ReactPaginate from 'react-paginate'
import { presse } from 'utils/presse'
import { useState } from 'react'

import { CultureCard } from 'Components/CultureCard/CultureCard'

export const CultureSlider = ({}) => {
    const [itemOffset, setItemOffset] = useState(0)
    const numCard = 4
    const endOffset = itemOffset + numCard
    const currentItems = presse.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(presse.length / numCard)

    const handlePageClick = (event) => {
        const newOffset = (presse.selected * numCard) % presse.length
        setItemOffset(newOffset)
    }

    return (
        <>
            <Grid container className="art-grid container humaPadding">
                {currentItems.map((article) => (
                    <Grid item sm={6} md={3} key={article.id}>
                        <CultureCard
                            link={article.link}
                            title={article.title}
                            image={article.image}
                            shortText={article.shortText}
                        />
                    </Grid>
                ))}
            </Grid>
            <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel=">>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<<"
                renderOnZeroPageCount={null}
            />
        </>
    )
}
