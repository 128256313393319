import React from 'react'
import './MyButton.scss'

export const ButtonYellow = ({ text, onclick }) => {
    return (
        <button className="button" onClick={onclick}>
            {text}
        </button>
    )
}
