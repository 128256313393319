import React from 'react'

export const NotFound = () => {
    return (
        <>
            <h1 style={{ marginTop: '250px' }}>
                Nous sommes désolés, cette page n'existe pas
            </h1>
        </>
    )
}
