import { Button } from 'Components/MyButton/Button'
import './HumaPresentation.scss'
import { Link } from 'react-router-dom'

export const HumaPresentation = () => {
    return (
        <>
            <article className="humaPadding mt20 ">
                <div className=" mt20">
                    <Button BtnText="Retour" />
                </div>
                <h2>Notre pôle humanitaire</h2>

                <p>
                    Nos projets à caractère Humanitaires sont portés par notre
                    Association Franco Ukrainienne BALZAC HANSKA qui, depuis
                    plus de 10 ans, possède une structure en France et en
                    Ukraine
                </p>
                <p>
                    Au moment de l’invasion de l’Ukraine la plupart de nos
                    collabolateurs ont choisi de rester en Ukraine aux côtés de
                    leurs familles.{' '}
                </p>
                <p>
                    Le Président Jean Roche, constamment au téléphone avec
                    l'équipe pour recueillir les informations les plus
                    pertinentes, a pris l’initiative de mettre en place un
                    circuit d’aide directe aux populations ukrainiennes les plus
                    démunies et en liaison avec les autorités régionales ou
                    locales concernées.
                </p>
                <p>
                    Nos collaborateurs et plus de 20 volontaires suivent la
                    distribution et la répartition de l’aide sur place, en
                    s’appuyant sur la structure déjà existante en Ukraine, que
                    nous avons fondées il y a plus de 10 ans.
                </p>
                <figure>
                    <img src="/Images/presentation/UkraineEnEurope.jpg" />
                    <figcaption>L'Ukraine en Europe</figcaption>
                </figure>
                <h3>Notre fonctionnement</h3>
                <p>
                    Pour vous impliquer dans cette aide humanitaire
                    soigneusement gérée et contrôlée, un point de collecte est
                    organisé par Association Franco-Ukrainienne « Balzac-Hanska
                    », association caritative culturelle :{' '}
                </p>
                <h3>
                    79 avenue la Bruyère – Vitry/Seine - Tél : 01 43 91 13 13 le
                    matin à partir de 9h et 06 79 43 75 16 de 9h à 20h{' '}
                </h3>
                <div className="right">
                    <img
                        src="/Images/tf1Berdytchiv4.jpg"
                        alt="tf1 à Berdytchiv"
                    />
                    <p>
                        Equipe TF1 nous a rendu visite dans nos locaux à
                        Berdytchiv sur son chemin de retour du Donbas en janvier
                        2023
                    </p>
                </div>
                <div className="pullquote2-wrapper">
                    <div className="pullquote2">
                        Liste des besoins que nous privilégions sont les
                        suivants :
                    </div>
                </div>
                <p>- Pharmacie, paramédical </p>
                <p>- Générateur Diesel</p>
                <p>- Nourriture longue conservation</p>
                <p>- Produits d’hygiène</p>
                <p>- Véhicules d’urgence et ambulances</p>
                <p>- Extincteurs – appareils de chauffage</p>
                <p>- Gâteaux, sucreries, chocolat</p>
                <p>
                    - Sous-vêtement thermique très basses températures et Sacs
                    de couchage -15° -25°
                </p>
                <p>- Vêtements pour enfants </p>
                <p>- Module de traitement d’eaux usées</p>
                <p>- Produits pour bébés</p>
                <p> - Module de traitement d’eau potable -</p>
                <div className="pullquote2-wrapper">
                    <div className="pullquote2">
                        Des listes plus détaillées et ciblées par rubriques et
                        liées aux demandes spécifiques des villes ou des régions
                        que nous soutenons peuvent être fournies sur demande.
                    </div>
                </div>
                <div className="pullquote">
                    <blockquote>
                        Pour ceux qui préfèrent nous aider financièrement, de
                        l’argent peut être viré sur un compte dédié de cette ONG
                        Association loi de 1901 N° W941007660 – ouvrant droit à
                        une réduction d’impôt en délivrant un certificat de
                        déductibilité fiscale. A cette fin veuillez indiquer sur
                        votre virement vos coordonnées (Nom, Prénom, adresse
                        électronique ou postale, éventuellement téléphone) Cpte
                        n°FR76 3007 6020 8918 4734 0020 022 BIC NORDFRPP
                    </blockquote>
                </div>
                <h3>La coordination de l’accueil de réfugiés :</h3>
                <p>
                    Cette coordination est ciblée sur les villes que nous
                    soutenons. Nous recueillons les demandes de besoins, que
                    nous relayons vers les organisations des villes Françaises
                    partenaires, ou les personnes de bonne volonté qui nous font
                    des propositions d’accueil.
                </p>
                <h3 className="secondPart">Pour mieux nous situer…. </h3>
                <p>Nos entrepôts de collecte et nos villes partenaires</p>
                <div>
                    <img
                        className="infoGrid"
                        src="/Images/presentation/PourMieuxNousConnaitre.jpg"
                        alt="Pour mieux nous connaitre"
                    />
                    <img
                        className="infoGrid"
                        src="/Images/presentation/PourMieuxNousConnaitreUkraine.jpg"
                        alt="Notre equipe en Ukraines"
                    />
                </div>
                <figure>
                    <img src="/Images/camion10/berdytchiv1.jpg" />
                    <figcaption>Gallery Balzac à Berdytchiv</figcaption>
                </figure>
                <h2>Ukraine a toujours besoin de nous - restons engagés !</h2>
                <div className="mb20">
                    <Button BtnText="Retour" />
                </div>
            </article>
        </>
    )
}
