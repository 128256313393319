import React, { useRef, useState } from 'react'
import './PdfReader.scss'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useParams } from 'react-router-dom'
import { getObject } from 'utils/projects'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { useReactToPrint } from 'react-to-print'
import { TransparentButton } from 'Components/MyButton/TransparentButton'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const PdfReader = ({ pdfFiles, pdfObj = getObject(pdfFiles) }) => {
    let { id } = useParams()

    const [numPages, setNumPages] = useState(1)
    // const [pageNumber, setPageNumber] = useState(1)
    // const [pageScale, setPageScale] = useState(1)

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }
    // const goToPrevPage = () =>
    //     setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)

    // const goToNextPage = () =>
    //     setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)

    return (
        <div>
            <center>
                <div>
                    {' '}
                    <div className="pdfReader">
                        {/* <TransparentButton text="Prev" onclick={goToPrevPage} /> */}
                        <TransparentButton
                            onclick={handlePrint}
                            text="Print"
                            bgc="white"
                        />
                        {/* <TransparentButton
                            text="Next"
                            onclick={goToNextPage}
                            disabled={pageNumber === numPages}
                        />{' '} */}
                    </div>
                    <Document
                        ref={componentRef}
                        file={pdfObj[id].link}
                        onLoadSuccess={onDocumentLoadSuccess}
                        // onContextMenu={(e) => e.preventDefault()}
                    >
                        {/* <Page style={{ margin: 20 }} pageNumber={pageNumber} /> */}
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ))}
                    </Document>
                    {/* <p>
                        Page {pageNumber} of {numPages}
                    </p> */}
                </div>
            </center>
        </div>
    )
}
