import { Grid } from '@mui/material'
import { Card } from 'Components/Card/Card'

import './AnnonceBalzacFest.scss'
import React from 'react'
import ReactPlayer from 'react-player/facebook'

import { CardText } from 'Components/Card/CardText'
import { ButtonYellow } from 'Components/MyButton/ButtonYellow'
import { Link } from 'react-router-dom'

export const AnnonceBalzacFest = ({ video }) => {
    return (
        <Grid
            container
            className="container react-player-container humaPadding"
        >
            <Grid item sm={12} md={6} className="player-wrapper">
                <ReactPlayer
                    className="react-player"
                    style={{ textAlign: 'center' }}
                    controls={true}
                    playing={false}
                    url={video}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 },
                        },
                        facebook: {
                            appId: '1289987928544379',
                        },
                    }}
                    width="100%"
                    heigth="100%"
                />
            </Grid>
            <Grid item sm={12} md={6} className="cardStyle">
                <Link to="/humanitaire">
                    <Card title="Balzac Fest" />

                    <CardText
                        text="<p class='pDarkTheme'><span class='first-character b'>P</span>our le 220e anniversaire de la naissance d'O. de Balzac, dans la période du 14 mars au 8 juillet 2019, Balzac Fest se tient en Ukraine.
Le programme de cette année couvre un large éventail d'événements qui auront lieu dans les villes que Balzac a visitées lors de ses voyages en Ukraine : Kiev, Jytomyr, Berdychiv et le village de Verkhivnia</p>"
                        element={<ButtonYellow text="Details" />}
                    />
                </Link>
            </Grid>
        </Grid>
    )
}
