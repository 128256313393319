import './PresentationCard.scss'

import { Link } from 'react-router-dom'

export const PresentationCard = ({ bgImage, span1, span2, span3, element }) => {
    return (
        <div className="card__img" style={{ '--img': `url(${bgImage})` }}>
            <div className="content">
                <h1 className="card__title animated fadeInDown">
                    <span>{span1}</span>
                    <span className="small">{span2}</span>
                    <span>{span3}</span>
                </h1>

                <div className="button">
                    <Link to={element}>LIRE PLUS</Link>
                </div>
            </div>
        </div>
    )
}
