import React, { useEffect } from 'react'
import './CulturePage.scss'
import { BalzacList } from 'Components/BalzacList/BalzacList'
import bgIm from 'Assets/maison.jpg'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'
import { TitleBlock } from 'Components/TitleBlock/TitleBlock'
import { CultureSlider } from 'Components/CultureSlider/CultureSlider'
import { Text } from 'Components/TitleBlock/Text'
import { AnnonceBalzacFest } from 'Components/VideoPlayer/AnnonceBalzacFest'
import { CardWithImage } from 'Components/Card/CardWithImage'

export const CulturePage = ({ slideUp, slideToUp, slideFade, slideToLeft }) => {
    useEffect(() => {
        slideUp('.box1', '0.6', '1')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        slideToLeft('.box', '0.1', '1')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        slideFade('.frame', '0.2', '1', '0.7')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="humaPadding"></div>
            <div id="nextSection" className="section">
                <div className="box">
                    <PresentationCard
                        bgImage={bgIm}
                        span1="Balzac"
                        span2="et"
                        span3="Hanska"
                        element="/culture/aboutUs"
                    />
                </div>
            </div>
            <section id="RouteBalzac" className="paddingTopHash box1">
                <TitleBlock title="LES VOYAGES D'HONORE DE BALZAC" />
                <Text
                    text="
                <span class='first-character b'>S</span>ans être un
                    grand voyageur dans l’âme, Balzac n’en a pas moins sillonné
                    la France et l’Europe tout au long de sa vie, quelques fois
                    pour affaires, le plus souvent pour rejoindre des femmes
                    aimées : les amies telle Zulma Carraud qu’il retrouve à
                    Angoulême ou Issoudun, les autres amantes ou les prétendues
                    au titre (respectivement Mme de Berny en Touraine puis en
                    bateau sur la Loire jusque Nantes, Mme de Castries en
                    Suisse) et surtout Mme Hanska pour laquelle il traversera
                    plusieurs fois l’Europe dans toute son étendue, dans des
                    conditions de voyage éprouvantes liées aux moyens de
                    transport rudimentaires de la première moitié du 19ème
                    siècle.
                "
                />

                <div className="box1">
                    <BalzacList />
                </div>
            </section>
            <section id="patrimoineUkrainien">
                <CultureSlider />
            </section>
            <section id="BalzacFest" className="balzacList">
                <AnnonceBalzacFest video="https://www.facebook.com/watch/?v=318282679046104" />
            </section>
        </>
    )
}
