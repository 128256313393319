//jusqu'aux 6 paragraphes du texte

export const humaNews = [
    {
        id: 1,
        title: 'Don de la FONDATION SETEC',
        image: '/Images/ambulance-setec/amb5.jpg',
        imageArray: [
            {
                image: '/Images/ambulance-setec/amb1.jpg',
                description: 'Chargement à Vitry',
            },

            {
                image: '/Images/ambulance-setec/amb2.jpg',
                description: '',
            },
            {
                image: '/Images/ambulance-setec/amb3.jpg',
                description: '',
            },
            {
                image: '/Images/ambulance-setec/amb4.jpg',
                description: '',
            },
            {
                image: '/Images/ambulance-setec/amb6.jpg',
                description: '',
            },
            {
                image: '/Images/ambulance-setec/amb7.jpg',
                description: '',
            },
            {
                image: '/Images/ambulance-setec/amb8.jpg',
                description: '',
            },
        ],

        date: '23 juin 2022',
        shortText:
            '15 000 euros nous ont été reversés pour l’achat d’une ambulance pour les services hospitaliers de la Ville de Berditchev en Ukraine.',
        text: [
            "Depuis le 24 février, date de l'invasion de l’Ukraine par la Russie, l'Ukraine a besoin d'aide dans de nombreux domaines, et notamment dans le domaine médical.<br/><br/>C’est pourquoi, l’Association franco-ukrainienne « Balzac-Hanska », representé par son président Jean ROCHE et ses nombreux partenaires, se sont engagés à une aide particulière et ciblée auprès des hôpitaux des zones dans lesquelles nous sommes présents.",

            'C’est dans ce contexte, sur demande de l’Hôpital de Berditchev, et après validation du besoin, qu’une ambulance a été transmise à cet Hôpital, avec l’implication des acteurs suivants comme donateurs classés par importance : <br/><br/>SETEC INGENIERIE, ASSOCIATION BALZAC HANSKA, ENSEMBLE UKRAINE, LE LIONS CLUB KIEV Ecology, LES FEMMES UKRAINIENNES EN FRANCE, PHARMACIENS SANS FRONTIERES, BETEN Ingéniérie, BALZAC INVESTISSEMENT, ainsi que Denis DUDNIK, et avec lui de nombreux volontaires, ayant aidé au chargement ou à la conduite de cette Ambulance. ',

            "L'ambulance a fait un long voyage de Vitry sur Seine vers l’étape de Berlin, puis Varsovie, où elle a été remise aux représentants de l’Hôpital municipal de Berditchev qui l’ont acheminée sur le territoire de l’Ukraine.",
        ],
    },
    {
        id: 2,
        title: 'Medaille "Pour la defence de l\'Ukraine"',
        image: '/Images/recompense/medaille1.jpeg',
        imageArray: [
            {
                image: '/Images/recompense/medaille1.jpeg',
                description: '',
            },
            {
                image: '/Images/recompense/medaille2.jpeg',
                description: '',
            },
            {
                image: '/Images/recompense/medaille3.jpeg',
                description: '',
            },
            {
                image: '/Images/recompense/medaille4.jpeg',
                description: '',
            },
        ],

        date: '5 aout 2022',
        shortText:
            'Le 5 août 2022 notre Directeur de BALZAK ET HANSKA UKRAINE Timothee Malonga a été decoré de la medaille « Pour la défense de l’Ukraine »! ',
        text: [
            'Le 5 août 2022 notre Directeur de BALZAK ET HANSKA UKRAINE Timothee Malonga a été decoré de la medaille « Pour la défense de l’Ukraine »! <br/><br/>Une recompense du président ukrainien Volodymyr Zelenskyy pour notre action humanitaire en Ukraine depuis l’invasion ignoble russe. <br/><br/>C’est un signe de reconnaissance des efforts de chacun de nous : de notre association, de volontaires en Ukraine et en France et des partenaires ukrainiens et français ',

            '',
        ],
    },
    {
        id: 3,
        title: "9me camion pour l'Ukraine",
        image: '/Images/camion9/20230130_141507.jpg',
        imageArray: [
            {
                image: '/Images/camion9/20230130_141507.jpg',
                description: 'Chargement à Vitry',
            },

            {
                image: '/Images/camion9/20230130_124221.jpg',
                description:
                    'Lait pour bebes chargé par notre benevole ukrainien Ivan',
            },
            {
                image: '/Images/camion9/20230130_110545.jpg',
                description:
                    'Xavier et Partricia de AFUF(Association des femmes ukrainiennes en France',
            },
            {
                image: '/Images/camion9/20230130_124309.jpg',
                description: 'Pas evident de manipuler la machine!',
            },
            {
                image: '/Images/camion9/20230130_141507.jpg',
                description: 'Le chargement presque terminé',
            },
            {
                image: '/Images/camion9/20230130_150130.jpg',
                description:
                    'Et après le travail - un repas convivial et bien merité',
            },
            {
                image: '/Images/camion9/IMG-20230206-WA0000.jpg',
                description: 'Arrivée à Berdytchiv',
            },
            {
                image: '/Images/camion9/IMG-20230206-WA0004.jpg',
                description: 'Dechargement à Berdytchiv le 5/02/2023',
            },
            {
                image: '/Images/camion9/IMG-20230206-WA0006.jpg',
                description: 'Dechargement',
            },
        ],

        date: '5 fevrier 2023',
        shortText:
            'Le 9me camion est parti de nos locaux à Vitry - sur - Seine le 23 janvier et est arrivé à Berdytchiv le 6 fevrier. ',
        text: [
            'Malheureusement, la guerre continue… Plus que jamais, les Ukrainiens ont besoin de notre aide! <br/><br/>Suite aux bombardements quotidiens de l’infrastructure civile, les russes laissent la population ukrainienne sans électricité et, par  conséquent, sans chauffage et lumière.<br/><br/><br/>Sans électricité les hôpitaux ne peuvent plus venir à l’aide aux malades et blessés, les écoles doivent fermer et le transport en commun de fonctionne plus.',

            'Le 9me camion est parti de nos locaux à Vitry - sur - Seine le 23 janvier et est arrivé à Berdytchiv le 6 fevrier.<br/><br/>Il a fait un court arret à Lviv ou 31 groupes electrogenes ont été dechargés pour en suite etre distribués partout en Ukraine. Les autres 64 serons distribués depuis Berdytchiv. Parmi les biens chargés il y a 10 palettes de lait pour les bebes, genereusement offerts par HEALTH & HAPINESS FRANCE - GOOD GOUT à notre precieux Patrick Angelvi de PHARMACIENS SANS FRONTIERS DU 94',

            'Merci à tous ceux qui nous ont aidé et soutenu pour cette action. Merci à nos généreux donateurs pour vos contributions en nature et/ou financières. Merci ASSOCIATIONN DES FEMMES UKRAINIENNE EN FRANCE , Lions Club Kiev Ecology, PSF 94, IDFU, Xavier Hervieux, Patrick Angelvi, Philippe Godillot. Et merci à tous nos bénévoles dont certains sont des réfugiés ukrainiens.',
            '',
        ],
    },

    {
        id: 4,
        title: "10me camion envoyé à l'Ukraine le 31 mars 2023",
        image: '/Images/camion10/berdytchiv1.jpg',
        imageArray: [
            {
                image: '/Images/camion10/berdytchiv1.jpg',
                description: 'Berdytchiv',
            },

            {
                image: '/Images/camion10/berdytchiv2.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry1.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry2.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry3.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry4.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry5.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry6.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry7.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry8.jpg',
                description: '',
            },
            {
                image: '/Images/camion10/Vitry9.jpg',
                description: '',
            },
        ],

        date: '31 mars 2023',
        shortText:
            'Plus de 150 pièces de literie neuves serons distribués aux deplacés dans la region de Berdytchiv et 10 palettes de produits paramedicaux pour les hopitaux. ',
        text: [
            "Le camion a quitté nos locaux à Vitry - sur - Seine le 01 avril après avoir chargé la literie COFEL à l'usine en Normandie. Il est arrivé à Berdytchiv le 05 avril, en passant par Lviv.",
            'Grace à nos chers amis et partenaires du "Collectif Unis Pour L\'Ukraine", nous avons encore une fois envoyé beaucoup de choses utiles et precieuses. Voici quelques exemples:',
            '-135 pièces de literie COFEL <br/>-20 m3 de vetementes chauds<br/>-14 groupes electrogenes grande puissance<br/>-Produits paramedicaux etc. ',
            'Merci à tous ceux qui nous ont aidé et soutenu pour cette action. Merci à nos généreux donateurs pour vos contributions en nature et/ou financières. Milles Mercis à l\'association "Partagence" de Claude Fregeac, la Mairie de Chennevieres, BIP Humanitaire, ASSOCIATION DES FEMMES UKRAINIENNE EN FRANCE , Lions Club Kiev Ecology, PSF 94, IDFU, Xavier Hervieux, Patrick Angelvi, Philippe Godillot, Kateryna Besnier,Groupe COFEL. Et merci à tous nos bénévoles qui sont toujours presents pour nous aider au chargement et dechargement!',
        ],
    },
    {
        id: 5,
        title: 'Creation du COLLECTIF ENSEMBLE AVEC UKRAINE',
        image: '/Images/logos/Logo_Balzac.jpg',
        imageArray: [
            {
                image: '/Images/logos/Visuel_logos_collectif_Ukraine.png',
                description: '',
            },
            {
                image: '/Images/camion9/20230123_165728.jpg',
                description: 'Chargement à Vitry',
            },
        ],

        date: 'mars 2023',
        shortText:
            " Six associations: PHARMACIENS SANS FRONTIERES DU 94, LES FEMMES UKRAINIENNES EN FRANCE, ILE DE FRANCE-UKRAINE, PARTAGENCE, UNITE LEGERE D'INTERVENTION ET DE SECOURS et l'ASSOCIATION FRANCO-UKRAINIENNE BALZAC ET HANSKA se sont reuniés pour apporter encore plus d'aide de soutiens à nos chers amis ukrainiens",
        text: [
            "Six associations: PHARMACIENS SANS FRONTIERES DU 94, LES FEMMES UKRAINIENNES EN FRANCE, ILE DE FRANCE-UKRAINE, PARTAGENCE, UNITE LEGERE D'INTERVENTION ET DE SECOURS et l'ASSOCIATION FRANCO-UKRAINIENNE BALZAC ET HANSKA se sont reuniés pour apporter encore plus d'aide de soutiens à nos chers amis ukrainiens",
            //     '<video controls width="100%" ><source src="../../Video/VID-20221224-WA0014.mp4" type="video/mp4" /><source src="../../Video/VID-20221224-WA0014.mp4" type="video/webm"/></video>Le camion a quité nos locaux à Vitry - sur - Seine le 23 janvier et est arrivé à Berdytchiv le 6 fevrier. Il a fait un court arret à Lviv ou 31 groupes electrogenes ont été dechargés pour  en suite etre distribués partout en Ukraine , ou on en a besoin le plus. Les autres 64 serons distrubués depuis Berdytchiv. ',
            //     'Parmi les biens chargés il y a 10 palettes de lait pour les bebes, genereusement offerts par HEALTH & HAPINESS FRANCE - GOOD GOUT à notre precieux Patrick Angelvi de PHARMACIENS SANS FRONTIERS DU 94',
            //     'Merci à tous ceux qui nous ont aidé et soutenu pour cette action. Merci à nos généreux donateurs pour vos contributions en nature et/ou financières. Merci ASSOCIATIONN DES FEMMES UKRAINIENNE EN FRANCE , Lions Club Kiev Ecology, PSF 94, IDFU, Xavier Hervieux, Patrick Angelvi, Philippe Godillot. Et merci à tous nos bénévoles dont certains sont des réfugiés ukrainiens.',
            "En effet, nous avons commencé à travailler ensemble depuis le debut de la grande offencive russe en fevrier 2022.<br/> C'est grace à nos efforts communs que nos actions ont pu etre realisées. ",
        ],
    },
]
