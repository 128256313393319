import { Grid } from '@mui/material'
import { Card } from 'Components/Card/Card'
import { CardSlider } from 'Components/Items/CardSlider'
import { Button } from 'Components/MyButton/Button'
import { Link } from 'react-router-dom'
import { pdfHumaFiles } from 'utils/pdfFiles'

export const PdfReaderHuma = () => {
    return (
        <>
            {' '}
            <Grid container className="humaPdfCard humaPadding">
                <Grid container>
                    <span style={{ width: '100%' }}>
                        {' '}
                        <Card title="Documents" />
                    </span>{' '}
                    <span>
                        <Button BtnText="Retour" />
                    </span>
                </Grid>

                {pdfHumaFiles.map((item) => (
                    <Grid item key={item.id} className="size">
                        <Link to={`/pdf/${item.id}`}>
                            <CardSlider
                                title={item.title}
                                text1={item.Date}
                                buttonText="Voir .pdf"
                                height={300}
                            />
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
