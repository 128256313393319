import React from 'react'
import './Card.scss'
import DOMPurify from 'dompurify'

export const CardText = ({ text, bgc, height, element }) => {
    return (
        <div
            className="cardBlock center-text "
            style={{ backgroundColor: bgc, height: height }}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text),
                }}
            ></div>
            <div style={{ textAlign: 'center' }}>{element}</div>
        </div>
    )
}
