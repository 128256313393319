export const pdfHumaFiles = [
    {
        id: 1,
        title: 'Nos actions en 2022',
        Date: '31.12.2022',
        link: '/Images/pdf/RAPPORTAIDEHUMANITAIREUKRAINE2022.pdf',
    },
    {
        id: 2,
        title: 'Reportage - camion n9',
        Date: '31.01.2023',
        link: '/Images/pdf/2023_02_27_RAPPORT_CAMION_9.pdf',
    },
    {
        id: 3,
        title: 'Newsletter n°1',
        Date: '15.12.2022',
        link: '/Images/pdf/Newsletter1.pdf',
    },
    {
        id: 4,
        title: 'Newsletter n°2',
        Date: '13.04.2023',
        link: '/Images/pdf/Newsletter2.pdf',
    },
]
export const pdfCultureFiles = [
    {
        id: 1,
        title: 'Nos actions en 2022',
        Date: '01.06.2019',
        link: '/Images/pdf/BalzacFestFR.pdf',
    },
]
