import React, { useEffect } from 'react'
import './MainPresentation.scss'

import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ScrollDown } from 'Components/ScrollDown/ScrollDown'
import { MainCard } from 'Components/MainCard/MainCard'
import { ButtonYellow } from 'Components/MyButton/ButtonYellow'
export const MainPresentation = ({ slideFade, slideUp }) => {
    useEffect(() => {
        slideFade('#parallax-container .frame', '1', '2')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        slideUp('#block0 .cardItemHuma', '0', '0.3')
        slideUp('#block1 .cardItemHuma', '0', '0.3')
        slideUp('#block2 .cardItemHuma', '0', '0.3')
        slideUp('#block3 .cardItemHuma', '0', '0.3')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div id="parallax-container">
                <div className="parallax-one">
                    <ScrollDown />
                </div>
                <Grid item xs={12} id="block0" className="block">
                    <div className="mainCard">
                        {' '}
                        <MainCard text="<p class='pDarkTheme'><span class='first-character b'>J</span>ean ROCHE, Ingénieur et chef d’entreprise travaillant en Ukraine depuis 1987 décide à la fin des années 1990 de chercher un terrain au cœur de la ville de BERDYTCHIV (Ukraine) pour y faire construire de nouveaux bureaux. C’est à ce moment là que nait la passion de Jean ROCHE pour BALZAC.<br/><br/>En effet le romancier H.de.BALZAC a séjourné à la fin de sa vie dans ce pays où vivait son amour, la contesse Ewelina HANSKA, avec qui il s'est marié dans cette ville.<br/><br/>Mais qui sait, hormis les érudits et les quelques centaines d’habitants du village de Verkhivnia, ou se trouve la manoir de Mme Hanska, que Balzac a passé près de deux ans et demi ici, au milieu du XIXe siècle ? Même les biographes et spécialistes du romancier n’ont pas tous fait le voyage jusqu’à ce coin reculé. Il faut dire que les dernières années de sa vie, de 1847 à 1850, furent les moins productives. <br/><br/>C’est pourtant bien là, dans cette « espèce de Louvre, de temple grec », qu’il écrivit la seconde partie de L’Envers de l’histoire contemporaine, le dernier roman de sa Comédie humaine. (Extrait du monde- Ariane Chemin) Jean ROCHE décide donc de faire connaitre cette partie oubliée de l’histoire de BALZAC.</p>" />
                    </div>
                </Grid>
                <div className="parallax-two">
                    <Link to="/culture">
                        <h2 className="frame">Projets Culturels</h2>
                    </Link>
                </div>{' '}
            </div>
            <div id="parallax-container-1">
                <Grid container className="block" id="block1">
                    <Grid item xs={12} md={4}>
                        <div className="mainCard">
                            <MainCard
                                text="<p class='pDarkTheme'><span class='first-character b'>B</span>eaucoup d’événements, de personnes, relient l’Ukraine à la
                            France (et la France à l’Ukraine) et l’amour epistolaire de
                            Balzac et de Madame Hanska en est un et l’un des
                            plus méconnus.<br/><br/>Depuis notre création en 2012, nous avons déjà réalisé quelques projets, notamment la création d’un musée du souvenir à
                            Berditchev dans le Centre Commercial «&nbsp;Galerie
                            Balzac&nbsp;», situé juste en face de l’église ou se
                            sont mariés Honoré de Balzac et Madame Hanska. </p>"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="mainCard">
                            {' '}
                            <MainCard
                                text='<p class="pDarkTheme"><span class="first-character b">N</span>ous
                            avons également apporté notre aide à la première
                            traduction en langue ukrainienne d’une des œuvres de
                            Balzac «Scènes de la vie parisienne».<br/>De nombreux autres projets se sont ensuite realisés, dont la "&nbsp;Balzac Fest&nbsp;" en 2019 l`occasion des 220 ans de la naissance de Balzac.<br/><br/>Mais notre projet
                            le plus important est à terme de pouvoir rénover le
                            Château de Verhivnya qui par manque de moyens, comme
                            malheureusement beaucoup d’endroits historiques en
                            Ukraine, se dégrade régulièrement. </p>'
                            />
                        </div>
                    </Grid>{' '}
                    <Grid item xs={12} md={4}>
                        <div className="mainCard">
                            <MainCard
                                text=' <p class="pDarkTheme">
                            <span class="first-character b">E</span>t ce serait une
                            énorme perte car un véritable petit musée y a été
                            créé, rattaché au Musée de Jytomyr, qui enferme de
                            vrai merveilles lié à Balzac, à la Comtesse Hanska,
                            et à leur amour. L`objectif à terme est de realiser une "Route de Balzac" entre Tours ou est né Balzac, le Chateau de Saché ou il a sejourné, la Maison de Balzac à Paris ou il a vécu, la Pologne, d`ou était originaire Mme Hanska, Berdytchiv et le Chateau de Verhivnia en Ukraine.
                        </p>'
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className="parallax-three">
                    <Link to="/humanitarian">
                        <h2 className="frame">Projets Humanitaires</h2>
                    </Link>
                </div>
                <Grid container className="block" id="block2">
                    <Grid item xs={12} md={6}>
                        <div className="mainCard">
                            <MainCard
                                text=' <p class="pDarkTheme">
                            <span class="first-character b">L</span>e 24
                            février 2022, la Russie a déclaré la guerre à
                            l’Ukraine. Cette invasion a jeté sur les routes plus
                            de 10 millions de personnes (en majorité des femmes,
                            des enfants et des personnes âgées). Plus de sept
                            millions de personnes ont été déplacées vers les regions ouest de l`Ukraine, et y seront encore pour longtemps, complètement déracinées. Certaines personnes issues de villes détruites à
                            plus de 90 % (Kharkiv, Marioupol, Tchernihiv,
                            Borodianka, Siverodonetsk…) pourront sans doute ne jamais retourner dans leur ville ou région d`origine.
                        </p>'
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="mainCard">
                            <MainCard
                                text='<p class="pDarkTheme"><span class="first-character b">C</span>’est pourquoi, l’Association franco-ukrainienne
                            « Balzac-Hanska », representé par son président Jean
                            ROCHE et ses nombreux partenaires, se sont engagés à
                            apporter de l’aide aux populations déplacées vers
                            les zones dans lesquelles nous sommes présents.Nous
                            ne sommes pas seuls dans cette mission . Avec nos
                            partenaires, les volontaires basés en France et
                            volontaires ukrainiens basés à Berdytchiv nous
                            mettont tout en oeuvre afin d`aider le peuple
                            ukrainien à traverser cette periode dramatique.
                        </p>'
                            />
                        </div>
                    </Grid>

                    <div className="container" style={{ margin: '20px auto' }}>
                        <Link to="/humanitarian/aboutUs">
                            <ButtonYellow text="Lire plus" />
                        </Link>
                    </div>
                </Grid>
            </div>
        </>
    )
}
