import { Button } from 'Components/MyButton/Button'
import './HumaPresentation.scss'
import { Link } from 'react-router-dom'

export const BalzacPresentation = () => {
    return (
        <>
            <article className="humaPadding mt20 ">
                <div className=" mt20">
                    <Button BtnText="Retour" />
                </div>
                <h2>Balzac et Hanska</h2>
                <p>
                    L'Association franco-ukrainienne Balzac et Hanska a été
                    créée dans le but de perpétuer, célébrer et rendre hommage à
                    la mémoire des amours epistolaires d'Honoré de Balzac et de
                    la Comtesse Ewelyna Hanska.
                    <br />
                    <br />
                    Nous voulons faire connaitre le lien de Balzac avec
                    l’Ukraine au travers de cette belle histoire et faire
                    revivre au travers d'une "Route de Balzac" le manoir ou il a
                    sejourné plus de deux ans en Ukraine.
                    <br />
                    <br />
                    Sur ce site, vous pourrez trouver l’histoire des voyages de
                    Balzac pour rejoindre son aimée.
                </p>

                <figure>
                    <img src="/Images/Balzac-image.jpg" />
                    <figcaption>Balzac pour Balzac-Fest 2019</figcaption>
                </figure>
                <h3> </h3>
                <p>
                    Pour aller rejoindre Madame Hanska, Balzac parcourait des
                    lieux, parfois pendant plusieres semaines.
                </p>
                <p>
                    Allant par la diligence de relais en relais ou en châteaux,
                    permettant de faire halte.
                </p>
                <p>
                    Par ailleurs il se rendait souvent à Saché, château des amis
                    de ses parents situé à cote de Blois ou il est né.
                </p>
                <h3></h3>
                <div className="right">
                    <img
                        src="/Images/hanskaFrameWhite.jpg"
                        alt="Ewelina Hanska"
                    />
                    <p>Ewelina Hanska</p>
                </div>
                {/* <div className="pullquote2-wrapper">
                    <div className="pullquote2">
                        Liste des besoins que nous privilégions sont les
                        suivants :
                    </div>
                </div> */}
                {/* <p>- Pharmacie, paramédical </p>
                <p>- Générateur Diesel</p>
                <p>- Nourriture longue conservation</p>
                <p>- Produits d’hygiène</p>
                <p>- Véhicules d’urgence et ambulances</p>
                <p>- Extincteurs – appareils de chauffage</p>
                <p>- Gâteaux, sucreries, chocolat</p>
                <p>
                    - Sous-vêtement thermique très basses températures et Sacs
                    de couchage -15° -25°
                </p>
                <p>- Vêtements pour enfants </p>
                <p>- Module de traitement d’eaux usées</p>
                <p>- Produits pour bébés</p>*/}
                <p>
                    Enfin il avait sa maison à Paris, aujourd'hui dans le 16me
                    arrondissement et transformé en musée , ainsi que le château
                    de Saché. Mais il a frequenté beaucoup d'autres lieux , par
                    exemple en Pologne.
                </p>
                <div className="pullquote2-wrapper">
                    <div className="pullquote2">
                        Le projet est de federer tous les lieux de passage et
                        nottament les plus emblematiques en créant une "Route de
                        Balzac" qui pourrait réunir dans un lien touristique
                        commun et attractif permettant de decouvrir ou
                        redecouvrir l'Europe autour d'un thème mondialement
                        connu et porteur.
                    </div>
                </div>
                <div className="pullquote">
                    <blockquote>
                        Si notre cause vous intéresse vous pourrez adhérer à
                        notre association, contribuer à nous aider dans nos
                        actions ou faire un don. N’hésitez pas à nous contacter,
                        nous sommes à votre disposition.
                    </blockquote>
                </div>

                <h3></h3>
                <p></p>
                {/* <h3 className="secondPart"></h3>
                <p></p>
                <div>
                    <img
                        className="infoGrid"
                        src="/Images/presentation/PourMieuxNousConnaitre.jpg"
                        alt="Pour mieux nous connaitre"
                    />
                    <img
                        className="infoGrid"
                        src="/Images/presentation/PourMieuxNousConnaitreUkraine.jpg"
                        alt="Notre equipe en Ukraines"
                    />
                </div> */}

                <figure>
                    <img src="/Images/balzacVoyage/maisonBalzacParis.jpg" />
                    <figcaption>Maison de Balzac à Paris</figcaption>
                </figure>
                <h2>Ukraine a toujours besoin de nous - restons engagés !</h2>
                <div className="mb20">
                    <Button BtnText="Retour" />
                </div>
            </article>
        </>
    )
}
