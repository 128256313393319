export const ourPartners = [
    '/Images/logos/AFUF.jpg',
    '/Images/logos/balzacInvestments.jpg',
    '/Images/logos/beten.jpg',
    '/Images/logos/ensembleUkraine.jpg',
    '/Images/logos/GalerieBalzac.jpg',
    '/Images/logos/IDFU.jpg',
    '/Images/logos/LionsCharentonLePont.jpg',
    '/Images/logos/lionsKyivEcology.jpg',
    '/Images/logos/lionsMontfermeilCoubron.jpg',
    '/Images/logos/LionsParisSeyssel.jpg',
    '/Images/logos/NeoTerra.jpg',
    '/Images/logos/PSF94.jpg',
    '/Images/logos/SETEC.jpg',
    '/Images/logos/URA.jpg',
    '/Images/logos/laTour.png',
    '/Images/logos/Logo_Club-Atheon_web_moto.png',
    '/Images/logos/neuf.png',
    '/Images/logos/partagence.png',
]
